import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';

import logo from './assets/logo.png'
import fullLogo from './assets/full-logo.png'

import menuIcon from './assets/menu-icon.png'

import heroImage from './assets/hero-image.png'
import heroImage2 from './assets/hero-image-2.png'

import img1_1 from './assets/1-1.png'
import img1_2 from './assets/1-2.png'
import img1_3 from './assets/1-3.png'
import img1_4 from './assets/1-4.png'
import img1_5 from './assets/1-5.png'

import icn2_1 from './assets/2-1.png'
import icn2_2 from './assets/2-2.png'
import icn2_3 from './assets/2-3.png'

import img3_1 from './assets/3-1.png'
import img3_2 from './assets/3-2.png'
import img3_3 from './assets/3-3.png'
import img3_4 from './assets/3-4.png'

import img4_1 from './assets/4-1.png'
import img4_2 from './assets/4-2.png'
import img4_3 from './assets/4-3.png'

import zorer from './assets/zorer.png'
import scotti from './assets/scotti.png'
import ratazzi from './assets/ratazzi.png'
import madaffari from './assets/madaffari.png'
import ledda from './assets/ledda.png'
import gambazza from './assets/gambazza.png'
import adriani from './assets/adriani.png'
import bandera from './assets/bandera.jpeg'
import moto from './assets/moto.png'
import collaboratori from './assets/collaboratori.png'

import connector from './assets/connector.png'
import connectorFlipped from './assets/connector-flipped.png'

import langItImport from './lang/lang.it.json'
import langEnImport from './lang/lang.en.json'

import phoneIcon from './assets/phone-icon.png'
import locationIcon from './assets/location-icon.png'
import instaIcon from './assets/insta-icon.png'
import mailIcon from './assets/mail-icon.png'

type TextStructure = {
  tabBar: {
    biker: string,
    medica: string,
    preparazione: string,
    chi: string,
    contatti: string
  },
  hero: {
    title1: string,
    title2: string
  },
  biker: {
    title: string,
    card1: {
      title: string,
      description: string
    },
    card2: {
      title: string,
      description: string
    },
    card3: {
      title: string,
      description: string
    },
    card4: {
      title: string,
      description: string
    },
    card5: {
      title: string,
      description: string
    }
  },
  medica: {
    title: string,
    card1: {
      title: string,
      description: string
    },
    card2: {
      title: string,
      description: string
    },
    card3: {
      title: string,
      description: string
    }
  },
  preparazione: {
    title: string,
    card1: {
      title: string,
      description: string
    },
    card2: {
      title: string,
      description: string
    },
    card3: {
      title: string,
      description: string
    },
    card4: {
      title: string,
      description: string
    }
  },
  chisiamo: {
    title: string,
    card1: {
      title: string,
      description: string
    },
    card2: {
      title: string,
      description: string
    },
    card3: {
      title: string,
      description: string
    },
    card4: {
      title: string,
      description: string
    },
    card5: {
      title: string,
      description: string
    },
    card6: {
      title: string,
      description: string
    },
    card7: {
      title: string,
      description: string
    },
    card8: {
      title: string,
      description: string
    },
    card9: {
      title: string,
      description: string
    },
  },
  dove: {
    title: string
  },
  barra: {
    posizione: string
  }
}

const langIt: TextStructure = langItImport
const langEn: TextStructure = langEnImport

type ImageCardProps = {
  title: string
  description: string
  image: string
}

const ImageCardFlipped: React.FC<ImageCardProps> = ({ title, description, image }) => {
  return (
    <div className='sm:flex sm:mx-8 sm:justify-between large:w-[1136px] large:mx-auto'>

      <div className='mx-4 sm:mx-0 tablet:w-[40%] tablet:relative'>
        <img className='rounded-md mx-auto mt-4 h-[180px] object-cover w-full xsm:h-[220px] sm:h-[250px] sm:w-[250px] tablet:w-full' src={image}></img>
        <img src={connectorFlipped} className='hidden tablet:block tablet:absolute tablet:left-[100%] tablet:top-7 tablet:w-[200px]'></img>
      </div>

      <div className='sm:text-right sm:w-[60%] tablet:w-[40%] sm:ml-4'>
        <h4 className='text-[#1FDF4B] font-custom-bold text-lg mt-4 mx-4 xsm:text-xl sm:mx-0 tablet:ml-0 desktop:text-2xl'>{title}</h4>

        {description.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            <p className='text-white font-custom text-sm mt-2 mx-4 sm:mx-0 tablet:w-full desktop:text-base'>{line}</p>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const ImageCard: React.FC<ImageCardProps> = ({ title, description, image }) => {
  return (
    <div className='sm:flex sm:mx-8 sm:justify-between large:w-[1136px] large:mx-auto'>
      <div className='sm:text-left sm:w-[60%] tablet:w-[40%] sm:mr-4'>
        <h4 className='text-[#1FDF4B] font-custom-bold text-lg mt-4 mx-4 xsm:text-xl sm:mx-0 desktop:text-2xl'>{title}</h4>
        {description.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            <p className='text-white font-custom text-sm mt-2 mx-4 sm:mx-0 tablet:w-full desktop:text-base'>{line}</p>
          </React.Fragment>
        ))}
      </div>

      <div className='mx-4 sm:mx-0 tablet:w-[40%] tablet:relative'>
        <img src={connector} className='hidden tablet:block tablet:absolute tablet:right-[100%] tablet:top-7 tablet:w-[200px]'></img>
        <img className='rounded-md mx-auto mt-4 h-[180px] object-cover w-full xsm:h-[220px] sm:h-[250px] sm:w-[250px] tablet:w-full' src={image}></img>
      </div>
    </div>
  );
};

// -------------------------------------------------------------------

type Language = 'it' | 'en'

function App() {

  const [language, setLanguage] = useState<Language>('it')
  const [text, setText] = useState<TextStructure>(langIt)

  const [showNav, setShowNav] = useState(false)

  let navModalRef = useRef(null)

  function toggleNavBar() {
    if (showNav) {
      (navModalRef?.current as any).className = 'nav-bar-hide'
      setShowNav(false)
      document.body.style.overflow = 'unset';
    } else {
      (navModalRef?.current as any).className = 'nav-bar-show'
      setShowNav(true)
      document.body.style.overflow = 'hidden';
    }
  }

  useEffect(() => {
    if (language == 'en') {
      setText(langEn)
    } else {
      setText(langIt)
    }
  })

  const bikerRef = useRef<HTMLElement>(null)
  const medicaRef = useRef<HTMLElement>(null)
  const preparazioneRef = useRef<HTMLElement>(null)
  const chiRef = useRef<HTMLElement>(null)
  const contattiRef = useRef<HTMLElement>(null)

  const scrollToBiker = () => {
    if (bikerRef.current) {
      bikerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const scrollToMedica = () => {
    if (medicaRef.current) {
      medicaRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const scrollToPreparazione = () => {
    if (preparazioneRef.current) {
      preparazioneRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const scrollToChi = () => {
    if (chiRef.current) {
      chiRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const scrollToContatti = () => {
    if (contattiRef.current) {
      contattiRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <div className="App">
      <div className='w-full z-30 relative'>
        <div className='bg-[#1A1A1A] h-16 flex items-center justify-between large:mx-auto fixed top-0 w-full rounded-b-3xl outline outline-[1px] outline-[#484848]'>
          <img src={logo} className='h-4 ml-5'></img>
          <img src={menuIcon} onClick={toggleNavBar} className='h-4 mr-5 hover:cursor-pointer tablet:hidden'></img>
          <div className='hidden tablet:flex'>
            <button className='text-white font-custom text-sm mr-4 hover:cursor-pointer' onClick={scrollToBiker}>{text.tabBar.biker}</button>
            <button className='text-white font-custom text-sm mr-4 hover:cursor-pointer' onClick={scrollToMedica}>{text.tabBar.medica}</button>
            <button className='text-white font-custom text-sm mr-4 hover:cursor-pointer' onClick={scrollToPreparazione}>{text.tabBar.preparazione}</button>
            <button className='text-white font-custom text-sm mr-4 hover:cursor-pointer' onClick={scrollToChi}>{text.tabBar.chi}</button>
            <button className='text-white font-custom text-sm mr-12 hover:cursor-pointer' onClick={scrollToContatti}>{text.tabBar.contatti}</button>
            <button className='text-white font-custom text-sm mr-4 hover:cursor-pointer' onClick={() => {
              if (language == 'it') {
                setLanguage('en')
              } else {
                setLanguage('it')
              }
            }}>{language == 'it' ?
              <>English</> :
              <>Italiano</>}
            </button>
          </div>
        </div>
      </div>

      <div id='nav-bar' ref={navModalRef} className='tablet:hidden'>
        <div className='flex flex-col mt-20'>
          <button className='text-white font-custom-bold text-xl mr-4 hover:cursor-pointer text-left ml-4 mt-8' onClick={() => { scrollToBiker(); toggleNavBar() }}>{text.tabBar.biker}</button>
          <button className='text-white font-custom-bold text-xl mr-4 hover:cursor-pointer text-left ml-4 mt-4' onClick={() => { scrollToMedica(); toggleNavBar() }}>{text.tabBar.medica}</button>
          <button className='text-white font-custom-bold text-xl mr-4 hover:cursor-pointer text-left ml-4 mt-4' onClick={() => { scrollToPreparazione(); toggleNavBar() }}>{text.tabBar.preparazione}</button>
          <button className='text-white font-custom-bold text-xl mr-4 hover:cursor-pointer text-left ml-4 mt-4' onClick={() => { scrollToChi(); toggleNavBar() }}>{text.tabBar.chi}</button>
          <button className='text-white font-custom-bold text-xl mr-4 hover:cursor-pointer text-left ml-4 mt-4' onClick={() => { scrollToContatti(); toggleNavBar() }}>{text.tabBar.contatti}</button>
          <button className='text-white font-custom-bold text-xl mr-4 hover:cursor-pointer text-left ml-4 mt-8' onClick={() => {
            if (language == 'it') {
              setLanguage('en')
            } else {
              setLanguage('it')
            }
          }}>{language == 'it' ?
            <>English</> :
            <>Italiano</>}
          </button>
        </div>
      </div>

      <section id='hero'>

        <div className='tablet:flex tablet:mx-8 large:w-[1136px] large:mx-auto mt-24'>
          <h1 className='text-white font-custom text-xl mt-4 mx-4 xsm:text-2xl sm:text-3xl sm:mt-8 sm:mx-8 tablet:w-[59%] tablet:text-left tablet:mt-0 tablet:mx-0'>{text.hero.title1}</h1>
        </div>

        <div className='mx-4 sm:mx-8 large:w-[1136px] large:mx-auto'>
          <img className='rounded-md mx-auto mt-4 tablet:hidden' src={heroImage}></img>
          <img className='rounded-md mx-auto mt-4 hidden tablet:block tablet:mt-[-40px]' src={heroImage2}></img>
        </div>

        <div className='mt-4'>
          {text.hero.title2.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              <h2 className='text-white font-custom text-base mx-4 sm:mx-8 xsm:text-lg large:w-[1136px] large:mx-auto'>{line}</h2>
            </React.Fragment>
          ))}
        </div>

      </section>

      <section id='biker' className='pt-3' ref={bikerRef}>

        <div className='mx-auto pb-8 bg-[#1A1A1A] mt-8 flex flex-col'>
          <h3 className='text-white font-custom-bold text-xl mt-8 xsm:text-2xl sm:text-3xl desktop:text-4xl'>{text.biker.title}</h3>

          <ImageCard
            title={text.biker.card1.title}
            description={text.biker.card1.description}
            image={img1_1}
          ></ImageCard>

          <ImageCard
            title={text.biker.card2.title}
            description={text.biker.card2.description}
            image={img1_2}
          ></ImageCard>

          <ImageCard
            title={text.biker.card3.title}
            description={text.biker.card3.description}
            image={img1_3}
          ></ImageCard>

          <ImageCard
            title={text.biker.card4.title}
            description={text.biker.card4.description}
            image={img1_4}
          ></ImageCard>

          <ImageCard
            title={text.biker.card5.title}
            description={text.biker.card5.description}
            image={img1_5}
          ></ImageCard>
        </div>

      </section>

      <section id='medica' className='pt-20' ref={medicaRef}>

        <h3 className='text-white font-custom-bold text-xl xsm:text-2xl sm:text-3xl desktop:text-4xl'>{text.medica.title}</h3>

        <ImageCardFlipped
          title={text.medica.card1.title}
          description={text.medica.card1.description}
          image={img4_2}
        ></ImageCardFlipped>

        <ImageCardFlipped
          title={text.medica.card2.title}
          description={text.medica.card2.description}
          image={img4_1}
        ></ImageCardFlipped>

        <ImageCardFlipped
          title={text.medica.card3.title}
          description={text.medica.card3.description}
          image={img4_3}
        ></ImageCardFlipped>
      </section>

      <section id='preparazione' className='pt-20' ref={preparazioneRef}>

        <h3 className='text-white font-custom-bold text-xl xsm:text-2xl sm:text-3xl desktop:text-4xl'>{text.preparazione.title}</h3>

        <ImageCard
          title={text.preparazione.card1.title}
          description={text.preparazione.card1.description}
          image={img3_1}
        ></ImageCard>

        <ImageCard
          title={text.preparazione.card2.title}
          description={text.preparazione.card2.description}
          image={img3_2}
        ></ImageCard>

        <ImageCard
          title={text.preparazione.card3.title}
          description={text.preparazione.card3.description}
          image={img3_3}
        ></ImageCard>

        <ImageCard
          title={text.preparazione.card4.title}
          description={text.preparazione.card4.description}
          image={img3_4}
        ></ImageCard>

      </section>

      <section id='chi-siamo' className='pt-20' ref={chiRef}>
        <h3 className='text-white font-custom-bold text-xl xsm:text-2xl sm:text-3xl desktop:text-4xl'>{text.chisiamo.title}</h3>

        <ImageCardFlipped
          title={text.chisiamo.card1.title}
          description={text.chisiamo.card1.description}
          image={ledda}
        ></ImageCardFlipped>

        <ImageCardFlipped
          title={text.chisiamo.card2.title}
          description={text.chisiamo.card2.description}
          image={madaffari}
        ></ImageCardFlipped>

        <ImageCardFlipped
          title={text.chisiamo.card3.title}
          description={text.chisiamo.card3.description}
          image={ratazzi}
        ></ImageCardFlipped>

        <ImageCardFlipped
          title={text.chisiamo.card4.title}
          description={text.chisiamo.card4.description}
          image={gambazza}
        ></ImageCardFlipped>

        <ImageCardFlipped
          title={text.chisiamo.card5.title}
          description={text.chisiamo.card5.description}
          image={adriani}
        ></ImageCardFlipped>

        <ImageCardFlipped
          title={text.chisiamo.card6.title}
          description={text.chisiamo.card6.description}
          image={zorer}
        ></ImageCardFlipped>

        <ImageCardFlipped
          title={text.chisiamo.card7.title}
          description={text.chisiamo.card7.description}
          image={scotti}
        ></ImageCardFlipped>

        <ImageCardFlipped
          title={text.chisiamo.card8.title}
          description={text.chisiamo.card8.description}
          image={bandera}
        ></ImageCardFlipped>

        <div className='sm:flex sm:mx-8 sm:justify-between large:w-[1136px] large:mx-auto'>

          <div className='mx-4 sm:mx-0 tablet:w-[40%] tablet:relative'>
            <img className='rounded-md mx-auto mt-4 h-[180px] object-cover w-full xsm:h-[220px] sm:h-[250px] sm:w-[250px] tablet:w-full' src={collaboratori}></img>
            <img src={connectorFlipped} className='hidden tablet:block tablet:absolute tablet:left-[100%] tablet:top-7 tablet:w-[200px]'></img>
          </div>

          <div className='sm:text-right sm:w-[60%] tablet:w-[40%] sm:ml-4'>
            <h4 className='text-[#1FDF4B] font-custom-bold text-lg mt-4 mx-4 xsm:text-xl sm:mx-0 tablet:ml-0 desktop:text-2xl'>{text.chisiamo.card9.title}</h4>
              <a href={"https://www.ortholabsport.it/"} target="_blank" className='text-white font-custom text-sm mt-2 mx-4 sm:mx-0 tablet:w-full desktop:text-base'>ORTHOLAB - https://www.ortholabsport.it/</a>
              <a href={"https://www.varesemotorsport.it/"} target="_blank" className='text-white font-custom text-sm mt-2 mx-4 sm:mx-0 tablet:w-full desktop:text-base'>VARESE MOTORSPORT - https://www.varesemotorsport.it/</a>
          </div>
        </div>

      </section>

      <section id='dove-siamo' className='pt-20'>
        <h3 className='text-white font-custom-bold text-xl xsm:text-2xl sm:text-3xl desktop:text-4xl'>{text.dove.title}</h3>
        <iframe className='rounded-lg mt-4 w-[calc(100%-32px)] mx-auto sm:w-[calc(100%-64px)] tablet:w-[700px]' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.774925949624!2d9.186869477515609!3d45.45419147107374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c403769dc9a1%3A0x9f9148dfe4e36c19!2sVia%20Giuseppe%20Vigoni%2C%2012%2C%2020122%20Milano%20MI!5e0!3m2!1sen!2sit!4v1721665918003!5m2!1sen!2sit" width="600" height="450" loading="lazy"></iframe>
      </section>

      <section id='contatti' ref={contattiRef}>

        <div className='mt-16 mx-0 bg-[#1A1A1A] pb-8'>
          <div className='flex flex-col large:w-[1136px] large:mx-auto '>
            <img src={fullLogo} className='w-[250px] sm:w-[400px] ml-4 mt-8'></img>
            <h5 className='mx-4 h-[1px] bg-[#474747] mt-8'></h5>
            <div className='flex items-center mt-8 ml-4'>
              <img className='w-4' src={mailIcon}></img>
              <h5 className='text-white font-custom mx-4 text-left text-base desktop:text-lg'>bpa2020srl@gmail.com</h5>
            </div>

            <div className='flex items-center mt-2 ml-4'>
              <img className='w-4' src={phoneIcon}></img>
              <h5 className='text-white font-custom mx-4 text-left text-base desktop:text-lg'>+39 345 137 6070</h5>
            </div>

            <div className='flex items-center mt-2 ml-4'>
              <img className='w-4' src={instaIcon}></img>
              <a href='https://www.instagram.com/racingphysiqueacademy' target="_blank" className='text-white font-custom mx-4 text-left text-base desktop:text-lg'>@racingphysiqueacademy</a>
            </div>

            <div className='flex items-center mt-2 ml-4'>
              <img className='w-4' src={locationIcon}></img>
              <h5 className='text-white font-custom mx-4 text-left text-base desktop:text-lg'>{text.barra.posizione}</h5>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
